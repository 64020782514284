import React, { useState, useEffect, PureComponent } from 'react'
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Button,  TextField } from '@mui/material';
import { Label, Send } from '@mui/icons-material';

const AthleteProgress = () => {
  const [stats, setStats] = useState();
  const [atheleteName, setAthleteName] = useState()
  const handleSubmit = async () => {
    if(atheleteName.length){
        
      const { data } = await axios.get(`https://pi-runners-app.azurewebsites.net/api/registrations?code=qTGCdzW5D_BYhkmDuoYEl3zBibe8guaKF4z4Vl_1tkbGAzFuw3Igjw==&entity=monthly_stats`);
      const athleteData = [...data].filter((a) => a.atheleteName == atheleteName).sort((a, b) =>
        //const sortData = [...data].sort((a, b) =>
        a.statsDate > b.statsDate ? 1 : -1,
        );
      
      setStats(athleteData)
    }
    
}
  
  
 
    return (
      <div>
      <h1>Athlete Analytics</h1>
      <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            

            <TextField id="atheleteName" label="Athlete Name" variant="standard" onChange={e => setAthleteName(e.target.value)}/>
            <Button variant="contained" endIcon={<Send />} onClick={handleSubmit}>
              Send
          </Button>
        </Box>
        <LineChart
          width={500}
          height={300}
          data={stats}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="statsDate" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="run" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="cycle" stroke="#82ca9d" />
          <Line type="monotone" dataKey="walk" stroke="#3182bd" />
          <Line type="monotone" dataKey="swimming" stroke="#ff0ff0" />
        </LineChart>
      
      </div>
    );
  
}

export default AthleteProgress
